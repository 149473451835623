const Rank = () => {
    return (
        <svg
            width="74"
            height="24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m7.5 15.781-3.867 2.344 1.025-4.395-3.398-2.959 4.482-.38L7.5 6.26l1.758 4.13 4.482.381-3.398 2.96 1.025 4.394L7.5 15.78ZM22.45 15.781l-3.868 2.344 1.026-4.395-3.399-2.959 4.483-.38L22.45 6.26l1.757 4.13 4.483.381-3.399 2.96 1.026 4.394-3.867-2.344ZM37.399 15.781l-3.867 2.344 1.025-4.395-3.398-2.959 4.482-.38L37.4 6.26l1.758 4.13 4.482.381-3.398 2.96 1.025 4.394L37.4 15.78ZM52.349 15.781l-3.868 2.344 1.026-4.395-3.399-2.959 4.483-.38 1.758-4.131 1.757 4.13 4.483.381-3.399 2.96 1.026 4.394-3.867-2.344ZM67.298 15.781l-3.867 2.344 1.025-4.395-3.398-2.959 4.482-.38 1.758-4.131 1.758 4.13 4.482.381-3.398 2.96 1.025 4.394-3.867-2.344Z"
                fill="#FE9015"
            />
        </svg>
    )
}

export default Rank
