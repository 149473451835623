const GHL_CUSTOM_FIELDS = {
    'contact.attestation': 'mVb3yv0oSeK8oqR2GJT1',
    'contact.sign': 'DIiY1rYq3BQYgf7uCfyF',
    'contact.confirmation': '1t6sQXcHw936UGviklBE',
    'contact.signature': 'xX8lanBh3p6wyQO1ShBQ',
    'contact.spouse_legal_last_name': 'fhTuT6XNT3OBAV41BCuq',
    'contact.do_you_have_tax_dependants': 'T32mH1iLzg6WiBbQDM8r',
    'contact.county': 'IocevIdNBY7CbXTUO2HJ',
    'contact.consent': '5uF9t3J96JGcbcTY6lnl',
    'contact.are_you_on_medicaid_or_medicare': '8uICpCIQB9c57GCjyEAP',
    'contact.do_you_have_a_spouse_': 'ZWGccgKmrPzyKTuheAWR',
    'contact.name_of_employer': 'DprJXPla7G674xP75KqJ',
    'contact.do_you_want_to_enroll_your_spouse_': 'ER6YbJNaYuGSwiJlb9Ft',
    'contact.estimated_household_annual_income': '5FOu1gu14bRwubQzi82e',
    'contact.do_you_have_a_2nd_dependent': 'RG2GiTuHugG9KF6bawSw',
    'contact.spouse_full_name': 'Zoh4A88lKt9WiVJHoRoZ',
    'contact.do_you_have_a_3rd_dependent': 'VSX70g9lIGRHg5BNvxB0',
    'contact.do_you_wish_to_enroll_spouse': 'cSq0eOYH0Ur2itsRp8Cm',
    'contact.authorization_date_': 'iGPkJtq1Lrvxe9VPBOXk',
    'contact.carrier_selected': 'hCwepAYVHI5d2HtMtjD0',
    'contact.spouse_ssn': 'hU1VD268bkZyKAOk3Gyk',
    'contact.front_of_licenseid': 'exU1LuhY9mG6XSydi5ov',
    'contact.primary_ssn': 'fgAXdn9beFBfYdUNINqI',
    'contact.front__back_of_government_issued_identification':
        'u9Geh5PwsEBYncopdKKX',
    'contact.do_you_wish_to_enroll_dependents': 'e0k6YOQC2o34WBI7nB61',
    'contact.current_insurance': 'ZbiD3hnNT84pA457jKSI',
    'contact.dependent_1_full_name': 'enQUkO6vwyuBeYZ6k52n',
    'contact.recent_employer': 'kTdPQ8ZWoK0E72V4hI4s',
    'contact.dependent_2_full_name': 'JPzanbVESKBBfzB07iri',
    'contact.are_you_a_us_national_': 'r2VMS714lKPo6rHLHzgq',
    'contact.dependent_3_full_name': 'ylLsP6EEhE0jWhFGrIIj',
    'contact.are_you_a_tobacco_user_': 'gRcDcg9quq0Gc2XK4aK1',
    'contact.dependent_4_full_name': 'jykGODfsGvNJTj62QasY',
    'contact.spouse_email': '934J60yW1oRfk3SpOH62',
    'contact.dependent_1_dob': 'yHxZLs5CDyrXDSPdBj6E',
    'contact.spouse_phone': 'SksVJr3DeI9PcnxO37Kl',
    'contact.dependent_2_dob': 'PAt2XpcToXIrfMHsSTTv',
    'contact.do_your_partner_uses_tobacco': 'YcZutXn31QB3UkFNhJWJ',
    'contact.dependent_3_dob': 'mjbVJnkwXERDEskv2vit',
    'contact.primary_dob': 'WUMEOlUI8lhiZ7LTdAYj',
    'contact.dependent_4_dob': 'olqQX4zZKc54gKikxOzD',
    'contact.dependent_1_ssn': 'VHEsDNv6Ix9GwDS5t7K1',
    'contact.dependent_2_ssn': 'Moa5K1Yp482YBwpYO2c8',
    'contact.dependent_3_ssn': 'ozEbYHCG3L7kzsCTRqRt',
    'contact.dependent_4_ssn': '4o064lnT1GRjubGlRfys',
    'contact.estimated_2024_household_annual_income': 'CczVAt4DLzYW8pi30RBw',
    'contact.gender': 'fgYtwa0upjyBqMjiaUCq',
    'contact.carrierplan_selected': '7xlpeE9YEuKowed0r78j',
    'contact.driver_license_or_goverment_issued_identification':
        'QPtL5PAXzJmcIM4VsUPv',
    'contact.consultant_name': 'XJnwbuI6R6GJ7dAasBPp',
    'contact.spouse_dob': 'A6TuSQjEDWrRNRVfFpui',
    'contact.yearly_household_income': '1e7gzXkHiQWbIYFJWqu6',
}

export default GHL_CUSTOM_FIELDS
